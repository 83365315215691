<template>
  <team-member-profile
    name="Claire Cook"
    qualifications="BSc(Hons) MSc(Clinical Research)"
    charter="MCSP"
    registration="HCPC Registered: PH: 65133"
    :pic="require(`@/assets/images/claire.jpg`)"
  >
    <p>
      Claire graduated from the University of Wales College of Medicine,
      Cardiff, in 2003 and has worked in the NHS ever since. She completed her
      core rotations at Walsall Manor NHS Hospital before moving out into the
      community working for Heart of Birmingham Primary Care Trust. Here she
      completed a year working in inner city GP practices in musculoskeletal
      physiotherapy before rotating into a community neuro rehab team where her
      passion for treating people with neurological conditions was ignited. She
      was promoted to team leader in 2008 before relocating to the South Coast
      in in 2011. She initially worked for the early supported stroke discharge
      team based at Queen Alexandra Hospital before settling into her current
      role in the Community Neuro Rehab team at Sussex Community NHS trust in
      2012.
    </p>
    <p>
      Claire is passionate about providing individualised care to support people
      with neurological conditions to achieve their goals in the community. In
      order to achieve this, Claire has been privileged to complete many
      practical and theoretical post graduate courses to broaden the depth of
      her knowledge whilst building experience through practice. These have
      included Bobath courses, a McKenzie course, a diploma in orthopaedic
      medicine, Tai Chi for health courses, neurofit exercise prescription,
      functional electrical stimulation, and taping and splinting for
      neurological conditions to name a few. She has also qualified as a
      reflexologist.
    </p>
    <p>
      Claire has further completed a Masters in Clinical Research at the
      University of Brighton in 2018 funded by Health Education England and the
      National Institute for Health Research. Her thesis was a research study
      exploring the experiences of impaired sensation in the feet related to
      people with Multiple Sclerosis through interviews. She is now undertaking
      a further Masters in Advance Clinical Practice at the University of
      Surrey.
    </p>
    <p>
      Claire is just starting her career in private practice and will continue
      to work within the NHS Monday to Thursday. She is currently running a
      seated Tai Chi group for the Multiple Sclerosis Society and has a limited
      number of private appointments available.
    </p>
    <p>
      Claire loves a challenge and when she is not working or studying, she can
      be found training for the next long-distance running event she has
      entered, trying to improve her golf or at a ballet class.
    </p>
  </team-member-profile>
</template>

<script>
import TeamMemberProfile from "../../layouts/TeamMemberProfile.vue";
export default {
  name: "Claire",
  components: {
    "team-member-profile": TeamMemberProfile,
  },
};
</script>
